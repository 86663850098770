import { isDuplicated } from "./helper"

export const updateCartContent = (state, payload) => ({
  ...state,
  cartContent: payload
})

export const updateCheckoutEvent = (state, payload) => {
  try {
    return {
      ...state,
      checkoutEvent: {
        ...state.checkoutEvent,
        cartSummary: structureCartSummary(state.checkoutEvent.cartSummary, payload),
        checkoutProducts: structureCheckoutProducts(state.checkoutEvent.checkoutProducts, payload)
      }
    }
  } catch (exception) {
    console.error('Error structuring checkout data: ', exception)
    return state
  }
}

export const structurePurchaseEvent = (state, payload) => {
  try {
    if (payload && payload.checkout && payload.checkout.orderNumber && parseInt(payload.checkout.orderNumber)){
      return {
        event: 'purchase',
        orderNumber: payload.checkout.orderNumber,
        deliveryDate: payload.shipping.deliveryMode.deliveryDate,
        cartSummary: state.checkoutEvent.cartSummary,
        transactionProducts: state.checkoutEvent.checkoutProducts
      }
    }
  } catch (exception) {
    console.error('Error structuring purchase data: ', exception)
    return undefined
  }
}

export const structureCartSummary = (state, payload) => ({
  ...state,
  cartTotal: payload.resume.cartTotal,
  totalPriceGain: payload.resume.totalPriceGain,
  totalGifts: payload.resume.totalGifts,
  totalPoints: payload.resume.totalPoints,
  totalTributes: payload.resume.totalTributes,
  totalCredits: payload.resume.totalCredits,
  shippingAmount: payload.response.shipping.deliveryMode.shippingAmount,
  shippingAmountDiscount: payload.response.shipping.deliveryMode.shippingAmountDiscount,
  shippingAmountWoDiscount: payload.response.shipping.deliveryMode.shippingAmountWoDiscount,
})

export const structureCheckoutProducts = (state, payload) => ({
  ...state,
  gifts: payload.giftsList,
  products: payload.finalList.map((item) => ({
    ...item,
    value: {
      buyingPrice: item.value.purchase / item.quantity,
      sellingPrice: item.value.sell / item.quantity,
      profit: item.value.gain / item.quantity,
      originalPrice: item.value.originalPrice / item.quantity
    },
  })),
  promotions: payload.response.promotions
})

export const eraseCartData = (state) => ({
  ...state,
  cartContent: undefined,
  cartAdditionOrigin: {
    origin: undefined,
    listName: undefined,
    listPosition: undefined
  }
})

export const updatePreSales = (state, payload) => {
  try {
    return {
      ...state,
      checkoutEvent: {
        ...state.checkoutEvent,
        checkoutProducts: {
          ...state.checkoutEvent.checkoutProducts,
          hasPreSales: payload.hasPreSales
        }
      }
    }
  } catch (exception) {
    console.error('Error structuring preSales data: ', exception)
    return state
  }
}

export const updateCartAdditionOrigin = (state, payload) => {
  try {
    return {
      ...state,
      cartAdditionOrigin: {
        origin: payload?.origin,
        listName: payload?.listName,
        listPosition: payload?.listPosition
      }
    }
  } catch (exception) {
    console.error('Error structuring cart addition origin data: ', exception)
    return state
  }
}

export const resetOriginInfo = (state) => ({
  ...state,
  cartAdditionOrigin: {
    origin: undefined,
    listName: undefined,
    listPosition: undefined
  }
})

export const structureCartProductQuantityClick = cartClickLabel => {
  try {
    return {
      event: 'productQuantityInteraction',
      cartClickLabel
    }
  } catch (exception) {
    console.error('Error structuring cart product quantity click: ', exception)
  }
}

export const structureDirectDeliveryClick = label => {
  try {
    const eventName = 'directDelivery' 

    if(!isDuplicated(eventName, label)){
      return {
        event: eventName,
        label
      }
    }
  }catch (exception) {
    console.error('Error structuring direct delivery click: ', exception)
  }
}

export const structureSplitPageButtonClick = splitButtonLabel => {
  try {
    const eventName = 'splitButtonClick' 

    if(!isDuplicated(eventName, splitButtonLabel)){
      return {
        event: eventName,
        splitButtonLabel
      }
    }
  }catch (exception) {
    console.error('Error structuring direct delivery click: ', exception)
  }
}

export const structureSearchCustomer = customerId => {
  try {
    return {
      event: 'searchCustomer',
      customerId
    }
  } catch (exception) {
    console.error('Error structuring direct delivery click: ', exception)
  }
}