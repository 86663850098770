import Cart from 'natura-commons/service/Cart'
import { get } from 'lodash'
import { DELIVERY_OPTION_AGENCY } from 'commons/constants/cart'

import { showLoad } from 'redux/actions/load/loadAction'

import {
  goToNextStep,
  getOrderShipping,
  removeItemCart
} from 'redux/actions/cart/cartAction'

import { refreshCartContent, updateAddressAction } from 'redux/actions/bag/bagAction'
import { mapDeliveryMode } from 'commons/interactor/cart/shipping/mapper'
import { renderAdressesShipping } from 'commons/presenter/cart/shipping/presenter'
import { showInvalidAddress } from 'commons/presenter/consultant/presenter'

import { setAlerts, verifyError } from 'redux/actions/alerts/alertsAction'
import { getError } from 'commons/presenter/errors/responseErros'

import {
  SET_ADDRESS_SHIPPING,
  SET_HOME_ADDRESS_SHIPPING,
  SET_TYPE_SHIPPING_AND_PRICE,
  SET_TYPE_SHIPPING_OPTIONS,
  SET_PICK_UP_ADDRESS,
  SET_SHIPPING_SELECTED_OPTION,
  SET_SHIPPING_CHANGING_OPTION,
  UPDATE_CART_RESUME,
  DISABLE_ADDRESS_SHIPPING,
  SET_DELIVERY_OPTION,
  SET_SELECTED_DELIVERY_MODE,
  CLEAR_SELECTED_DELIVERY_MODE,
  SET_REFRESH_TOKEN
} from '../constants'
import { setPurchaseEventData } from '../dataLayer/dataLayerAction'

export const setAddressShipping = (objAddress) => (dispatch) => {
  dispatch({
    type: SET_ADDRESS_SHIPPING,
    payload: objAddress
  })
}

export const setHomeAddressShipping = objAddress => (dispatch) => {
  dispatch({
    type: SET_HOME_ADDRESS_SHIPPING,
    payload: objAddress
  })
}

export const setDeliveryModeShipping = objAddress => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DELIVERY_MODE,
    payload: objAddress
  })
}

export const clearDeliveryModeShipping = () => (
  {
    type: CLEAR_SELECTED_DELIVERY_MODE,
  }
)

const handlePersonWithoutDeliveryMode = (dispatch) => {
  dispatch({
    type: DISABLE_ADDRESS_SHIPPING,
    payload: { enableShippingAddress: false }
  })

  dispatch({
    type: SET_DELIVERY_OPTION,
    payload: { deliveryOption: DELIVERY_OPTION_AGENCY }
  })
}

const getShippingAddress = (addresses, deliveryType, getState) => {
  if (deliveryType === 'AGENCY') {
    const { sucursalReducer: { fields: { selectedAgency } } } = getState()

    const pickupPoint = {
      code: selectedAgency.code ? selectedAgency.code : null,
      status: selectedAgency.status ? selectedAgency.status : null,
    }

    const agencyAddress = {
      addressType: { id: 2 },
      addressUse: [{ id: 1 }],
      ...addresses[0],
      id: 1,
      complement: null,
      extraInfo: null,
      number: null,
      zipCode: null,
      deliveryModes: [
        ...addresses[0].deliveryModes.map((item) => (setPickupPointInfo(item, pickupPoint)))
      ]
    }

    addresses.splice(0, 1, agencyAddress);

    return addresses;
  }

  return addresses.filter(address => address.deliveryModes.length)
}

const setPickupPointInfo = (item, pickupPoint) => ({ ...item, pickupPoint })

export const setAddressesAndDeliveryModes = ({ addresses }, deliveryType) => (dispatch, getState) => {
  const { cartReducer, userSession, orderShippingReducer, parameterReducer } = getState()
  const shippingAddress = getShippingAddress(addresses, deliveryType, getState)

  if (!shippingAddress.length && deliveryType === 'HOME') {
    if (parameterReducer.enablePickupPoint) {
      handlePersonWithoutDeliveryMode(dispatch)
    } else {
      return dispatch(setAlerts(showInvalidAddress()))
    }
  }
  
  if (!userSession?.commercialInfo?.isTestProfile) {
    dispatch({ type: SET_REFRESH_TOKEN })
  }

  if (shippingAddress.length) {
    const [selectedAddress] = renderAdressesShipping(shippingAddress, userSession?.commercialInfo?.countryCode)
    const fullSelectedAddress = addresses.find(item => item.id === selectedAddress?.id)
    const deliveryModes = get(fullSelectedAddress, 'deliveryModes', [])
    const deliveryPolicyId = get(selectedAddress, 'deliveryPolicyId', null)
    const externalId = get(selectedAddress, 'externalId', null)
    const shippingMethodId = get(selectedAddress, 'shippingMethodId', null)

    const allDeliveryModes = deliveryModes.map(item => mapDeliveryMode(item, getState))
    let selectedDeliveryMode = allDeliveryModes.find(item => item.id === selectedAddress?.id)

    if (deliveryType === 'AGENCY') {
      [selectedDeliveryMode] = allDeliveryModes
    }

    const objAddress = {
      addressShipping: {
        ...selectedAddress,
        deliveryPolicyId,
        externalId,
        shippingMethodId,
      },
      addresses: shippingAddress
    }
    updateAddressAction(objAddress, dispatch, getState)

    if (deliveryType === 'HOME') {
      dispatch({
        type: SET_HOME_ADDRESS_SHIPPING,
        payload: {
          addressShipping: selectedAddress
        }
      })
    }

    dispatch({
      type: SET_ADDRESS_SHIPPING,
      payload: {
        addressShipping: selectedAddress,
        addresses: shippingAddress,
        deliveryMode: selectedDeliveryMode,
        selectedIndex: 0, // First
      }
    })

    dispatch({
      type: SET_TYPE_SHIPPING_OPTIONS,
      payload: {
        deliveryModes: allDeliveryModes,
        addresses: shippingAddress,
      }
    })

    dispatch({
      type: UPDATE_CART_RESUME,
      payload: {
        resume: cartReducer.resume,
        objTypeAndPrice: { type: selectedDeliveryMode, index: 0 },
        orderShippingReducer
      }
    })
  }
}

export const setTypeShippingAndPrice = (objTypeAndPrice) => (
  dispatch,
  getState
) => {
  const { cartReducer, orderShippingReducer } = getState()
  const addressPickUp = objTypeAndPrice.type.addressPickUp
  objTypeAndPrice.addressPickUp = undefined
  dispatch({
    type: SET_TYPE_SHIPPING_AND_PRICE,
    payload: {
      objTypeAndPrice,
      addressPickUp,
      orderShippingReducer
    }
  })
  dispatch({
    type: UPDATE_CART_RESUME,
    payload: {
      resume: cartReducer.resume,
      objTypeAndPrice,
      addressPickUp,
      orderShippingReducer
    }
  })
}

export const setLocalPickUpAddress = (objAddress) => (dispatch) => {
  dispatch({
    type: SET_PICK_UP_ADDRESS,
    payload: objAddress
  })
}

export const updateDeliveryModes = deliveryModes => (dispatch, getState) => {
  const allDeliveryModes = deliveryModes.map(item => (mapDeliveryMode(item, getState)))

  dispatch({
    type: SET_TYPE_SHIPPING_OPTIONS,
    payload: {
      deliveryModes: allDeliveryModes
    },
  })
}

export const setShippingSelectedOption = objSelected => (dispatch) => {
  dispatch({
    type: SET_SHIPPING_SELECTED_OPTION,
    payload: {
      objSelected
    }
  })
  dispatch(getOrderShipping())
}

export const changingShippingOption = (shippingChanging) => (dispatch) => {
  dispatch({
    type: SET_SHIPPING_CHANGING_OPTION,
    payload: {
      shippingChanging
    }
  })
  dispatch(getOrderShipping())
}

export const checkoutCart = (data) => (dispatch, getState) => {
  let bankName = '', cardBrandName = '';
  if (data) {
    bankName = (data.bank) ? data.bank : '';
    cardBrandName = (data.brand) ? data.brand : '';
  }
  const {
    bagReducer: {
      paymentConditionSelected,
      products,
    },
    cartReducer: {
      resume: { cartUid },
      methodPayment
    },
    orderShippingReducer: { addresses },
    userSession: { commercialInfo: { orderProfile } }
  } = getState()

  const { paymentConditions: paymentConditionsComercial } = orderProfile

  let paymentID = null

  const paymentConditions = paymentConditionSelected && paymentConditionSelected.length ? 
    paymentConditionSelected :
    paymentConditionsComercial

  if (typeof methodPayment !== 'undefined' && methodPayment) {
    (methodPayment == 'card') ? paymentID = 3 : paymentID = 4;
  }

  const cart = new Cart()
  cart.resolver(
    (response) => {
      dispatch(setPurchaseEventData(response))
      if (response && response.checkout && response.checkout.orderNumber) {
        refreshCartContent(response, dispatch, getState)
        dispatch(goToNextStep())
      } else {
        if (response.error) {
          verifyError(dispatch, response.error)
        }
        dispatch(showLoad(false))
      }
    },
    (error) => {
      dispatch(showLoad(false))
      const responseError = get(error, ['errors', 0, 'extensions', 'response', 'body', 'errors', 0], {})
      const product = products.find(item => Number(item.code) === Number(responseError.productCode))
      if (responseError.code === 5015) {
        dispatch(removeItemCart(product))
      }
      verifyError(dispatch, error)
    },
    cart.checkoutCart({
      addresses,
      paymentID,
      bankName,
      cardBrandName,
      cartUid,
      paymentConditions,
      orderProfile,
    })
  )
  dispatch(showLoad(true))
}

export const checkoutValidateCart = () => (dispatch, getState) => {
  const {
    bagReducer: { paymentConditionSelected },
    orderShippingReducer: { addresses },
    userSession: { commercialInfo: { orderProfile } }
  } = getState()

  const { paymentConditions: paymentConditionsComercial } = orderProfile

  const paymentConditions = paymentConditionSelected && paymentConditionSelected.length ?
    paymentConditionSelected :
    paymentConditionsComercial

  const cart = new Cart()
  dispatch(showLoad(true))
  cart.resolver(
    (response) => {
      dispatch(setPurchaseEventData(response))
      if (response && response.checkoutValidate &&
        response.checkoutValidate.erros && response.checkoutValidate.errors.length > 0) {
        if (response.error) {
          verifyError(dispatch, response.error)
        }
        dispatch(showLoad(false))
      } else {
        refreshCartContent(response, dispatch, getState)
        dispatch(goToNextStep())
      }
    },
    (error) => {
      dispatch(showLoad(false))
      verifyError(dispatch, error)
    },
    cart.checkoutValidateCart({
      addresses,
      paymentConditions,
      orderProfile,
    })
  )
}
