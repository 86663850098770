import { format, add, differenceInBusinessDays, parse } from 'date-fns'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import timezones from 'commons/enums/timezone'
import { maskToCountry } from 'commons/helpers/formatter'

const LIMA_TZ = 'America/Lima'
const MALASIA_MY = 'Asia/Kuala_Lumpur'

export const parseFormatDate = (date, pattern) => {
  const pdate = typeof date === 'string' ? parse(date, pattern, new Date()) : date

  return new Date(
    pdate.getUTCFullYear(),
    pdate.getUTCMonth(),
    pdate.getUTCDate(),
    pdate.getUTCHours(),
    pdate.getUTCMinutes(),
    pdate.getUTCSeconds(),
  )
}

export const formatDate = (date, pattern, timeZone) => {
  const dateLocale = timeZone ? utcToZonedTime(new Date(date), timeZone) : new Date(date)

  return format(dateLocale, pattern)
}

export const formatDateIso = date => toDate(date).toISOString()

export const showDateByCountry = (date, country) => {
  switch ((country || '').toUpperCase()) {
    case 'MY':
      return formatDate(date, 'MM/dd/yyyy')
    default:
      return formatDate(date, 'dd/MM/yyyy')
  }
}

export const toDateLocale = (date, timeZone = LIMA_TZ) => {
  if (!date) return null
  timeZone = (timeZone === 'PE') ? LIMA_TZ : MALASIA_MY
  return toDate(date, { timeZone })
}

export const diffDate = (startDate, endDate, timeZone) => {
  timeZone = (timeZone === 'PE') ? LIMA_TZ : MALASIA_MY
  if (!startDate || !endDate) {
    return null
  }
  const now = getHourLocaleNow(timeZone)
  const dateEnd = getHourLocale(endDate, timeZone)
  const dateStart = getHourLocale(startDate, timeZone)

  let microSecondsDiff = Math.abs(dateEnd - now)
  const nDuration = Math.abs(dateEnd - dateStart)
  microSecondsDiff /= 1000
  const seconds = Math.floor(microSecondsDiff % 60)
  microSecondsDiff /= 60
  const minutes = Math.floor(microSecondsDiff % 60)
  microSecondsDiff /= 60
  const hours = Math.floor(microSecondsDiff % 24)
  const days = Math.floor(microSecondsDiff / 24)
  const percentBar = Math.abs(getPercentBar(days, hours, minutes, nDuration))
  return {
    showTimer: days < global.DaysShowTime,
    days,
    hours,
    percentBar: percentBar.toString().concat('%'),
    minutes: minutes < 10 ? '0'.concat(minutes) : minutes,
    seconds: seconds < 10 ? '0'.concat(seconds) : seconds
  }
}

const getPercentBar = (days, hours, minutes, duration) => {
  const remanning = days * 1440 + hours + minutes
  const durationTotal = duration / 1000 / 60
  return (remanning / durationTotal) * 100 - 100
}

const getHourLocale = (hour, timeZone = LIMA_TZ) => toDate(hour, { timeZone }).getTime()

export const getHourLocaleNow = (locale = LIMA_TZ) => getHourLocale(new Date(), locale)

export const addDaysToDate = days => format(add(new Date(), { days }), 'dd/MM/yyyy')

export const diffBusinessDays = (endDate, startDate) => ((endDate && startDate) ? differenceInBusinessDays(new Date(endDate), new Date(startDate)) : '')

export const formatCountryDeliveryDate = (deliveryDate = new Date(), translate, countryCode) => {
  const UTCdate = toDate(deliveryDate || new Date(), timezones.UTC)
  const timezoneLocale = timezones[countryCode] ? timezones[countryCode] : timezones.UTC
  const dateLocale = utcToZonedTime(UTCdate, timezoneLocale)
  const formattedDate = formatDate(dateLocale || new Date(), maskToCountry(countryCode))

  return `${translate.byKey('deliveryTimeComplete')} ${formattedDate}`
}
