import { get } from 'lodash';

export const initialState = {
  filter: false,
  list: [],
  listPagined: [],
  productsNextCicle: [],
  unavailableOpportunitiesModal: {
    open: false,
  },
}

export const mapProduct = (listItem, sourceTags = []) => {
  const listProducts = []
  if (!listItem || listItem.length < 1) return listProducts

  listItem.map((item, index) =>
    listProducts.push(mapProductItem(item, 0, index, sourceTags))
  )
  return listProducts
}

export const mapUnavailableProducts = products =>
  products
    .map(product => ({
      ...product,
      quantity: product.quantity || 1,
      inBag: true
    }))
    .map((product, index) => mapProductItem(product, product.quantity, index))
export const appendTags = (tagsToAppend, targetTagList = []) => {
  if (Array.isArray(tagsToAppend)) {
    const productSearchByMatcher = /^products?[.]search[.]by[.](text|code)$/
    const productSearchByAlreadyExists = targetTagList.some(tag => productSearchByMatcher.test(tag))
    targetTagList.push(...tagsToAppend.filter(tagToAppend => (
      (productSearchByAlreadyExists && (productSearchByMatcher.test(tagToAppend))) ? false :
        !targetTagList.includes(tagToAppend)
    )))
  }
  return { appendTags: nextTags => appendTags(nextTags, targetTagList), output: () => targetTagList }
}
export const mapProductItem = (item, quantity = 0, index = 0, sourceTags = []) => {
  const shouldShowDiscountPrice =
    item.totalPriceWoDiscounts && item.totalPriceWoDiscounts < item.totalPrice

  const { tags } = item

  const finalTags = appendTags(tags).appendTags(item.unavailabilityFix?.tags)
    .appendTags(item.productUnavailability?.tags)
    .appendTags(sourceTags)
    .output()

  return {
    inBag: item.inBag || false,
    name: item.description,
    description: item.description,
    code: item.productCode.toString(),
    image: item.picture != null ? item.picture : item.image,
    companyBrand: item.companyBrand,
    restrictGift: Boolean(item.restrictGift),
    restrictSale: Boolean(item.restrictSale),
    points: item.points,
    isExchangeInformation: !!item.exchangeInformation,
    showOriginalPrice: shouldShowDiscountPrice,
    brand: item.brand,
    subBrand: item.subBrand,
    priceSell: item.priceWoDiscounts,
    taxCategoryCode: item.taxCategoryCode,
    value: {
      originalPrice: item.price,
      oldPurchase: item.price === item.priceWoDiscounts ? null : item.price,
      ...assignProductValue(item)
    },
    valuesToUseTax: {
      priceWoDiscounts: item.priceWoDiscounts,
      priceWoProfitability: item.priceWoProfitability,
      productCode: item.productCode,
      profitability: item.profitability,
      sellingPrice: item.sellingPrice,
      sellingPriceWoProfitability: item.sellingPriceWoProfitability,
    },
    quantity,
    isAvailableChecked: item.isAvailableChecked,
    isPromotion: item.isPromotion != null ? item.isPromotion : false,
    isDiamondDiscount:
      item.isDiamondDiscount != null ? item.isDiamondDiscount : false,
    discount: item.discount != null ? item.discount : null,
    isAvailable: item.availability,
    isPlanB:
      !item.availability &&
      item.productUnavailability &&
      item.productUnavailability.productsFix &&
      item.productUnavailability.productsFix.length > 0,
    isGuaranteedSale: !!item.guaranteedSelling,
    tags: finalTags,
    unavailabilityMessage:
      item.unavailabilityFix &&
        item.unavailabilityFix.productsFix &&
        item.unavailabilityFix.productsFix.length > 0
        ? item.unavailabilityFix.productsFix[0].message
        : '',
    unavailabilityFix:
      !item.availability &&
        item.productUnavailability &&
        item.productUnavailability.productsFix &&
        item.productUnavailability.productsFix.length > 0
        ? item.productUnavailability.productsFix.map(fix => ({
          ...fix,
          tags: appendTags(fix.tags).appendTags(item.productUnavailability.tags)
            .output()
        }))
        : null,
    index
  }
}

export const mapExclusiveProduct = (listItem, sourceTags = []) => {
  const listProducts = []
  let index = 0

  for (const item of listItem) {
    const shouldShowDiscountPrice =
      item.priceWoDiscounts && item.priceWoDiscounts < item.price

    const hasProductsFix = item.checkedProduct?.productUnavailability?.productsFix?.length > 0

    const finalTags = appendTags(item.tags).appendTags(item.unavailabilityFix?.tags)
      .appendTags(item.productUnavailability?.tags)
      .appendTags(sourceTags)
      .output()
    const result = {
      ...item,
      name: item.description,
      companyBrand: item.companyBrand,
      code: item.productCode.toString(),
      image: item.image || item.picture,
      points: item.points,
      showOriginalPrice: shouldShowDiscountPrice,
      isAvailable: item.checkedProduct.availability,
      isPlanB: !item.checkedProduct.availability && hasProductsFix,
      isExchangeInformation: item.isExchangeInformation,
      priceSell: item.priceWoDiscounts,
      value: {
        unitPrice: item.price,
        originalPrice: item.price,
        purchase: item.purchase || item.priceWoProfitability,
        sell: item.sellingPrice,
        gain: item.sellingProfitability,
        sellingPriceWoProfitability: item.sellingPriceWoProfitabilityPromotion || item.sellingPriceWoProfitability,
        sellingPrice: item.sellingPrice,
        sellingProfitability: item.sellingProfitability
      },
      priceWoDiscounts: item.priceWoDiscounts,
      priceWoProfitability: item.priceWoProfitability,
      productCode: item.productCode,
      profitability: item.profitability,
      sellingPrice: item.sellingPrice,
      sellingPriceWoProfitability: item.sellingPriceWoProfitability,
      tags: finalTags,
      index: index++,
      checkedProduct: { 
        ...item.checkedProduct,
        unavailabilityFix: hasProductsFix ? item.checkedProduct.productUnavailability.productsFix : null,
      },
      unavailabilityFix: hasProductsFix ? item.checkedProduct.productUnavailability.productsFix : null,
    }
    listProducts.push(result)
  }
  return listProducts
}

export const formatIncorporationProduct = (product, sourceTags = []) => {
  const shouldShowDiscountPrice =
    product.priceWoDiscounts && product.priceWoDiscounts < product.price

  const finalTags = appendTags(product.tags).appendTags(product.unavailabilityFix?.tags)
    .appendTags(product.productUnavailability?.tags)
    .appendTags(sourceTags)
    .output()

  const formatedProduct = {
    name: product.description,
    companyBrand: product.companyBrand,
    code: product.code.toString(),
    image: product.image || product.picture,
    points: product.points,
    showOriginalPrice: shouldShowDiscountPrice,
    isAvailable: product.isAvailable,
    isPlanB: product.isPlanB,
    isExchangeInformation: product.isExchangeInformation,
    value: {
      unitPrice: product.priceSell,
      originalPrice: product.priceSell,
      purchase: product.value.purchase,
      sell: product.sellingPrice,
      gain: product.sellingProfitability,
      sellingPriceWoProfitability: product.sellingPriceWoProfitabilityPromotion || product.sellingPriceWoProfitability,
      sellingPrice: product.sellingPrice,
      sellingProfitability: product.sellingProfitability
    },
    tags: finalTags,
  }

  return formatedProduct
}

export const mapCartProduct = (
  listItem,
  starterKitCode = null,
  starterKitMandatory
) => {
  const listProducts = []
  let index = 0

  for (const item of listItem) {
    const shouldShowDiscountPrice =
      item.totalPriceWoDiscounts && item.totalPriceWoDiscounts < item.totalPrice

    const unitPriceWoProfitability = get(item, 'itemDetails.[0].unitPriceWoDiscounts', item.unitPrice)
    const unitPrice = get(item, 'itemDetails.[0].unitPrice', item.unitPrice)

    const result = {
      cartItemUid: item.cartItemUid,
      inBag: true,
      name: item.description,
      code: item.productCode.toString(),
      companyBrand: item.companyBrand,
      ignoreCredit: item.ignoreCredit,
      ignoreMinOrderSize: item.ignoreMinOrderSize,
      image: item.image || item.picture,
      points: item.totalPoints,
      showOriginalPrice: shouldShowDiscountPrice,
      incorporationUid: item.incorporationUid,
      restrictGift: Boolean(item.restrictGift),
      restrictSale: Boolean(item.restrictSale),
      value: {
        //compra
        // originalPriceWithProfitability: item.originalPriceWithProfitability, ALT DE/POR
        // unitPriceWoProfitability: item.itemDetails[0].unitPriceWoProfitability, ALT DE/POR

        originalPriceWithProfitability: 0,
        unitPriceWoProfitability,

        //venta
        totalPrice: item.totalPriceWoDiscounts,
        unitPrice,
        // unitSellingPrice: item.unitSellingPrice === item.unitPrice ? item.itemDetails[0].unitPrice : item.unitSellingPrice, ALT DE/POR
        
        unitSellingPrice: item.totalPriceWoDiscounts,

        //ganancia total
        // gain: item.gain || item.totalProfitability, ALT DE/POR
        gain: item.totalProfitability,

        //items total
        totalOriginalPrice: item.totalOriginalPrice,
        totalPriceWoProfitability: item.totalPriceWoProfitability,
      },
      isStarterKit:
        starterKitCode !== null && starterKitMandatory
          ? starterKitCode.includes(item.productCode)
          : false,
      isStarterKitOptional:
        starterKitCode !== null && !starterKitMandatory
          ? starterKitCode.includes(item.productCode)
          : false,
      isPromotion: item.isPromotion != null ? item.isPromotion : false,
      isDiamondDiscount:
        item.isDiamondDiscount != null ? item.isDiamondDiscount : false,
      discount: item.discount != null ? item.discount : null,
      diamondDiscount:
        item.diamondDiscount != null ? item.diamondDiscount : null,
      quantity: item.quantity,
      isAvailable: item.cartItemUid ? item.quantity > 0 : item.isAvailable,
      isPlanB:
        (!(item.quantity > 0) || !item.availability) &&
        item.productUnavailability &&
        item.productUnavailability.productsFix &&
        item.productUnavailability.productsFix.length > 0,
      isGuaranteedSale: !!item.guaranteedSelling,
      unavailabilityFix:
        item.productUnavailability &&
          item.productUnavailability.productsFix &&
          item.productUnavailability.productsFix.length > 0
          ? item.productUnavailability.productsFix
          : null,
      index: index++
    }
    listProducts.push(result)
  }
  return listProducts
}

export const mapGiftObject = (itemGift, titlesPromotion) => {
  const { benefit = {} } = itemGift
  const { productCodes, chooseFromList, distincts } = benefit

  let selected = 0
  if (productCodes && productCodes.length > 0) {
    productCodes.map((product, index) => {
      selected = product.included && index
    })
  }
  selected = selected || 0
  const item = productCodes && productCodes.length > 0 && productCodes[selected]
  const isAvailable = item.availability != null ? item.availability : true
  let title = null
  let requiredList = []

  if (titlesPromotion) {
    const result = titlesPromotion.find(
      title =>
        title.cdPromocao === itemGift.promotionId &&
        title.cdVersao === itemGift.promotionVersionId
    )
    if (result) {
      title = result.dsPromocao
      result.listaStep && result.listaStep.forEach((step) => {
        step.listaRequisito?.length > 0
          && step.listaRequisito.forEach(req => requiredList.push(req))
      })

      requiredList = requiredList
        .filter(req => req.listaProduto && req.listaProduto.length > 0)
        .map(req => ({ quantity: req.qtRequisito, products: req.listaProduto }))
    }
  }

  return {
    cartItemUid: undefined,
    inBag: true,
    name: item.description,
    title,
    code: item.productCode.toString(),
    companyBrand: item.companyBrand,
    image: item.picture != null ? item.picture : item.image,
    chooseFromList: chooseFromList || false,
    quantity: benefit.quantity > 0 ? benefit.quantity : item.quantity,
    quantityBenefit: benefit.quantity,
    isAvailable,
    pendingPromotion: itemGift.pendingPromotion,
    promotionId: itemGift.promotionId,
    promotionStepNumber: itemGift.promotionStepNumber,
    promotionVersionId: itemGift.promotionVersionId,
    alternateProducts:
      (item.alternateProducts &&
        item.alternateProducts.map(alternativeProduct =>
          mapAlternateProducts(alternativeProduct, itemGift)
        )) ||
      [],
    selected: !isAvailable ? false : selected,
    distincts,
    requiredList,
    restrictGift: Boolean(item.restrictGift),
    restrictSale: Boolean(item.restrictSale),
  }
}

export const mapAlternateProducts = (
  item,
  gift,
  parentIndex,
  giftItems = []
) => {
  const selectedGift = giftItems.find(({ productCode, itemDetails }) => (
    productCode === item.productCode
    && itemDetails[0].promotions.some(promotion => promotion.promotionId === gift.promotionId)
  ))

  return {
    cartItemUid: undefined,
    inBag: true,
    name: item.description,
    code: item.productCode.toString(),
    companyBrand: item.companyBrand,
    included: item.included,
    selected: item.included,
    image: item.picture != null ? item.picture : item.image,
    quantity: selectedGift ? getQuantityGift(selectedGift, item) : 0,
    isAvailable: item.availability != null ? item.availability : true,
    pendingPromotion: gift.pendingPromotion,
    points: item.points,
    promotionId: gift.promotionId,
    promotionStepNumber: gift.promotionStepNumber,
    promotionVersionId: gift.promotionVersionId,
    alternateProducts: item.alternateProducts && item.alternateProducts.length > 0
      ? item.alternateProducts.map(row => mapAlternateProducts(row, gift, parentIndex, giftItems))
      : item.alternateProducts,
    parentIndex,
    restrictGift: Boolean(item.restrictGift),
    restrictSale: Boolean(item.restrictSale),
  }
}

export const mapUnavailableGift = giftItems =>
  giftItems.map(gift => ({
    ...gift,
    itemTypeId: 2,
    quantity: gift.quantity ? gift.quantity : 1
  }))

export const mapIncorporatedGifts = giftItems =>
  giftItems
    .filter(gift => gift.itemTypeId === 4)
    .map(gift => ({
      cartItemUid: gift.cartItemUid,
      ignoreCredit: gift.ignoreCredit,
      ignoreMinOrderSize: gift.ignoreMinOrderSize,
      inBag: true,
      name: gift.description,
      code: gift.productCode.toString(),
      image: gift.itemDetails[0].image,
      chooseFromList: false,
      isAvailable: true,
      quantity: gift.quantity,
      options: [],
      fromIncorporation: true,
      restrictGift: Boolean(gift.restrictGift),
      restrictSale: Boolean(gift.restrictSale),
    }))

const isUnavailable = option => option.isAvailable === false
  && (option.alternateProducts.length === 0 || option.alternateProducts.every(option => option.isAvailable === false))

export const mapGiftsProduct = (listItem, giftItems, titlesPromotion) => {
  const listProducts = []
  let i = 0
  if (listItem && listItem.length > 0) {
    for (const item of listItem) {
      if (item.benefit.benefitTypeId < 3) {
        const gift = mapGiftObject(item, titlesPromotion)
        gift.options = []
        if (gift.chooseFromList) {
          const { productCodes } = item.benefit
          for (const product of productCodes) {
            gift.options.push(mapAlternateProducts(product, gift, i, giftItems))
            gift.isAvailable = true
          }
          gift.quantity *= item.quantity
          if (gift.distincts && gift.quantity > 1) {
            gift.distincts = false
          }
          if (gift.options.length >= 1 && gift.options.every(isUnavailable)) {
            gift.options[0].quantity = gift.quantity
            gift.options[0].selected = true
          }
          listProducts.push(gift)
          i++
        } else {
          i = item.quantity
          const { productCodes } = item.benefit
          const gifts = transformOptionsToGift(
            item,
            productCodes,
            titlesPromotion
          )
          listProducts.push(...gifts)
        }
      }
    }
  }
  return listProducts
}

export const sortOptionsByCode = options =>
  options.sort((optionA, optionB) => {
    if (parseInt(optionA.code) > parseInt(optionB.code)) {
      return 1
    }
    return -1
  })

export const transformOptionsToGift = (promotion, options, titlesPromotion) => {
  let title = null
  if (titlesPromotion) {
    const result = titlesPromotion.find(
      title =>
        title.cdPromocao === promotion.promotionId &&
        title.cdVersao === promotion.promotionVersionId
    )
    if (result) {
      title = result.dsPromocao
    }
  }

  return options.map((item) => {
    const isAvailable = item.availability != null ? item.availability : true
    const quantity = item.quantity * promotion.quantity
    return {
      cartItemUid: undefined,
      inBag: true,
      name: item.description,
      code: item.productCode.toString(),
      companyBrand: item.companyBrand,
      title,
      image: item.picture != null ? item.picture : item.image,
      chooseFromList: false,
      isAvailable,
      quantity,
      pendingPromotion: promotion.pendingPromotion,
      promotionId: promotion.promotionId,
      promotionStepNumber: promotion.promotionStepNumber,
      promotionVersionId: promotion.promotionVersionId,
      points: item.points,
      alternateProducts: item.alternateProducts.map(alternativeProduct =>
        mapAlternateProducts(alternativeProduct, item)
      ),
      selected: item.isAvailable ? false : item.included,
      included: item.included,
      options: [],
      restrictGift: Boolean(item.restrictGift),
      restrictSale: Boolean(item.restrictSale),
    }
  })
}

export const mapProductNextCicle = (listItem) => {
  const listProducts = []
  if (!listItem) {
    return []
  }
  for (const item of listItem) {
    if (item.isAvailable) {
      const result = {
        ...item,
        inBag: false,
        isAvailable: null,
        isAvailableChecked: true,
      }
      listProducts.push(result)
    }
  }
  return listProducts
}

const getQuantityGift = (gift, item) => {
  if (gift) return gift.quantity
  return item.included && item.quantity >= 0 ? item.quantity : 0
}

export const mapProductsAvailability = (codes) => {
  const result = []
  codes.map((item) => {
    result.push({ productCode: item, availability: false })
  })

  return result
}


const assignProductValue = (item) => {
  const { sellingPrice, sellingProfitability } = item
  const purchase = item.purchase || item.priceWoProfitability
  const sell = item.sell || item.sellingPrice
  const gain = item.gain || sellingProfitability
  const sellingPriceWoProfitability = item.sellingPriceWithTaxes || item.sellingPriceWoProfitability

  return {
    sellingProfitability,
    sellingPriceWoProfitability,
    sellingPrice,
    purchase,
    sell,
    gain
  }
}
