import { allProductsAction } from 'commons/interactor/search/interactor'
import {
  productsNextCicleAction,
  getInputTextSearch,
  getPriority
} from 'commons/interactor/products/interactor'
import { appendTags } from 'commons/interactor/products/mapper'


import { ORDER } from 'redux/actions/router/constants'

import { findAvailability, findHighlightedAvailability, checkAvailableProducts } from 'redux/actions/search/searchAction'
import { orderBy } from 'lodash'

import {
  PRODUCT_LIST,
  PRODUCT_LIST_NEXT_CICLE,
  PRODUCT_ADDED_IN_BAG,
  PRODUCT_REMOVED_FROM_BAG,
  PRODUCT_LIST_SIMILAR,
  CLEAR_PRODUCT_LIST_SIMILAR,
  CLEAR_LIST_PRODUCTS,
  PRODUCT_LIST_PAGINED,
  PRODUCT_LIST_PAGINED_PROMOTION,
  SORT_INTERNAL,
  PRODUCTS_VITRINE
} from '../constants'

const regex = /^\d+$/

export const listProducts = (list = null) => (dispatch, getState) => {
  const { bagReducer, router } = getState()
  const {
    location: { pathname }
  } = router
  const result = allProductsAction(list, bagReducer)
  dispatch({ type: PRODUCT_LIST, list: result })
  pathname !== ORDER && dispatch(listProductsPagined())
}

export const listProductsPagined = () => async (dispatch, getState) => {
  let {
    list,
    listPagined,
    paginationSize,
    searchLabel,
    shouldOptimize,
    filterSelected,
    sorterSelected
  } = getPropertys(getState)

  const isSearchByCode = regex.test(searchLabel)
  if (!isSearchByCode && list.length) {
    list = filterSearch(list, filterSelected, sorterSelected)

    if (list.length !== listPagined.length) {
      let index = listPagined.length === 0 ? 0 : listPagined.length
      if (index === 0) {
        listPagined = [...list.filter(item => item.isPromotion === true)]
        index += listPagined.length
      }
      const resultPaged = [...list].splice(index, paginationSize)
      const pagination = listPagined.concat(resultPaged)

      dispatch({ type: PRODUCT_LIST_PAGINED, listPagined: pagination })
      let productsCode = getNextItems(list, index, paginationSize)
      dispatch(findAvailability(productsCode))

      if (shouldOptimize) {
        index = pagination.length
        productsCode = getNextItems(list, index, paginationSize)
        dispatch(findAvailability(productsCode))
      }
    }
  }
}

export const paginedPromotions = (isSearch = false) => async (
  dispatch,
  getState
) => {
  const {
    paginationSize,
    vitrine,
    searchLabel,
    shouldOptimize,
    filterSelected,
    sorterSelected
  } = getPropertys(getState)
  let nextList = true
  if (vitrine.length) {
    vitrine.map((item, index) => {
      if (!nextList) return
      let { products, listPagined } = item
      products = filterSearch(
        products,
        filterSelected,
        sorterSelected,
        searchLabel
      )
      if (products.length !== listPagined.length) {
        let startIndex = listPagined.length === 0 ? 0 : listPagined.length

        if (isSearch) {
          listPagined = []
          startIndex = 0
        }

        if (startIndex === 0) {
          listPagined = [...products.filter(item => item.isPromotion === true)]
          startIndex += listPagined.length
        }
        const resultPaged = [...products].splice(startIndex, paginationSize)
        const pagination = listPagined.concat(resultPaged)
        dispatch({
          type: PRODUCT_LIST_PAGINED_PROMOTION,
          listPagined: pagination,
          index
        })
        let productsCode = getNextItems(products, startIndex, paginationSize)
        dispatch(findAvailability(productsCode, index))
        if (shouldOptimize) {
          startIndex = pagination.length
          productsCode = getNextItems(products, startIndex, paginationSize)
          dispatch(findAvailability(productsCode, index))
        }
        nextList = false
        return
      }
      nextList = true
    })
  }
}

export const highlightedAvailability = () => async (
  dispatch,
  getState
) => {
  const { highlighted } = getPropertys(getState)
  if (highlighted.length) {
    highlighted.forEach((item, index) => {
      const productCodes = item.products.map(product => parseInt(product.code))
      dispatch(findHighlightedAvailability(productCodes, index))
    })
  }
}

export const sortProductsPagined = sorter => (dispatch, getState) => {
  if (sorter) {
    const { columnSorter, typeOrder } = sorter
    const { paginationSize, list } = getPropertys(getState)
    dispatch({ type: PRODUCT_LIST_PAGINED, listPagined: [] })
    const listSorter = orderBy(list, [columnSorter], [typeOrder])
    dispatch({ type: PRODUCT_LIST, list: listSorter })
    const productsCode = getNextItems(listSorter, 0, paginationSize)
    if (productsCode.length) {
      findAvailability(productsCode)
      dispatch(listProductsPagined())
      return
    }
    dispatch(listProductsPagined())
  }
}

export const sortPromotions = sorter => (dispatch, getState) => {
  if (sorter) {
    const { columnSorter, typeOrder } = sorter
    const { vitrine } = getPropertys(getState)
    if (vitrine.length) {
      vitrine.map((item, index) => {
        item.products = orderBy(item.products, [columnSorter], [typeOrder])
        dispatch({
          type: PRODUCT_LIST_PAGINED_PROMOTION,
          listPagined: [],
          index
        })
      })
    }
    dispatch({ type: SORT_INTERNAL, vitrine })
    dispatch(paginedPromotions())
  }
}

export const clearPaginationVitrine = () => (dispatch, getState) => {
  const { vitrine } = getPropertys(getState)
  if (vitrine.length) {
    vitrine.map((item, index) => {
      dispatch({
        type: PRODUCT_LIST_PAGINED_PROMOTION,
        listPagined: [],
        index
      })
    })
    dispatch({ type: SORT_INTERNAL, vitrine })
  }
}

export const clearSorterPagined = (isVitrine = false) => (
  dispatch,
  getState
) => {
  const { list, vitrine } = getPropertys(getState)
  if (!isVitrine) {
    const result = orderList(list)
    dispatch({ type: PRODUCT_LIST, list: result })
    dispatch({ type: PRODUCT_LIST_PAGINED, listPagined: [] })
    dispatch(listProductsPagined())
    return
  }

  if (vitrine.length) {
    vitrine.map((item, index) => {
      item.products = orderList(item.products, true)
      dispatch({
        type: PRODUCT_LIST_PAGINED_PROMOTION,
        listPagined: [],
        index
      })
    })
  }
  dispatch({ type: SORT_INTERNAL, vitrine })
  dispatch(paginedPromotions())
}

const getNextItems = (list, startIndex, paginationSize) => {
  const productsCode = []
  const items = [...list].splice(startIndex)
  items.forEach((item) => {
    if (!item.isAvailableChecked && productsCode.length < paginationSize) {
      productsCode.push(parseInt(item.code))
    }
  })
  return productsCode
}

const getPropertys = (state) => {
  const {
    productsReducer: { list, listPagined },
    parameterReducer: { paginationSize, highlighted, vitrine, shouldOptimize },
    customSearch: { searchLabel },
    sorterSearchReducer: { filterSelected, sorterSelected }
  } = state()

  return {
    highlighted,
    list,
    listPagined,
    paginationSize,
    searchLabel,
    shouldOptimize,
    vitrine,
    filterSelected,
    sorterSelected
  }
}

const orderList = (list, isVitrine = false) => (!isVitrine
  ? orderBy(list, ['isPromotion', 'index'], ['desc', 'asc'])
  : orderBy(list, ['index'], ['asc']))

export const setProductsVitrine = () => (dispatch, getState) => {
  const { vitrine, filterSelected } = getPropertys(getState)
  if (vitrine.length) {
    const list = []
    vitrine.map((item) => {
      let { products } = item
      products = filterSearch(products, filterSelected)
      list.push(...products)
    })
    dispatch({ type: PRODUCTS_VITRINE, products: list })
  }
}

export const filterSearch = (
  products,
  filterSearch = [],
  sorterSelected = null,
  searchLabel = null
) => {
  // search
  if (searchLabel && products.length) {
    const searchByCode = regex.test(searchLabel)
    if (searchByCode) {
      products = [...products].filter(product =>
        product.code.includes(searchLabel)
      )
    } else {
      products = [...products].filter(product =>
        product.name.toUpperCase().includes(searchLabel.toUpperCase())
      )
    }
  }

  // filter
  if (filterSearch.length) {
    const result = []
    filterSearch.map((filter) => {
      const arr = products.filter(
        product =>
          product.brand === filter.value || product.subBrand === filter.value
      )
      result.push(...arr)
    })
    products = [...new Set(result)]
  }

  // order
  if (sorterSelected) {
    const { columnSorter, typeOrder } = sorterSelected
    const listSorter = orderBy(products, [columnSorter], [typeOrder])
    products = [...listSorter]
  }
  return products
}

export const listProductsNextCicle = () => (dispatch, getState) => {
  dispatch({
    type: PRODUCT_LIST_NEXT_CICLE,
    productsNextCicle: productsNextCicleAction(getState)
  })
}

export const listProductsSimilar = (item, products) => (dispatch) => {
  dispatch({
    type: PRODUCT_LIST_SIMILAR,
    productsSimilar: {
      listSimilar: getPriority(item, products.map(product => ({
        ...product,
        tags: appendTags(item.tags)
          .appendTags(item.productUnavailability && item.productUnavailability.tags)
          .output()
      }))),
      itemSelected: item,
      inputTextSearch: getInputTextSearch(item)
    }
  })
}

export const clearListProductsSimilar = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_LIST_SIMILAR })
}

export function addedInBag(item) {
  return {
    type: PRODUCT_ADDED_IN_BAG,
    item
  }
}

export function removedFromBag(item) {
  return {
    type: PRODUCT_REMOVED_FROM_BAG,
    item
  }
}

export const clearListProducts = () => ({
  type: CLEAR_LIST_PRODUCTS
})

export const checkAvailableSaleProduct = (productCode) => async (dispatch)  => {
  const products = await dispatch(checkAvailableProducts([productCode]))

  const product = products.find(product => product.productCode === productCode)

  return product && product.availability
}
