import Translate from 'natura-commons/translate'

import {
  formatterAddress,
} from 'commons/constants/address'

import {
  TYPE_MODAL_CHANGE_SHIPPING_ADDRESS,
  TYPE_MODAL_SHIPPING_PICKUP
} from 'redux/actions/constants'

const translate = new Translate()

export const showAlertAddress = (
  typeAddress,
  setAlertAddress,
  functionOnChangeValue,
  addresses = null
) => {
  let title = `${translate.byKey('changeAddressShipping')}`
  if (typeAddress === TYPE_MODAL_SHIPPING_PICKUP) {
    title = `${translate.byKey('changeBranch')}`
  }
  const objAlert = {
    typeAddress,
    titleModal: title,
    objAddress: renderAdresses(typeAddress, addresses),
    handleConfirm: functionOnChangeValue
  }
  setAlertAddress(objAlert)
}

export const setSelectedOption = (
  type,
  selectedIndex,
  selected,
  dispatchSetSelectedOption
) => {
  const objectSelected = {
    type,
    index: selectedIndex,
    selected
  }
  dispatchSetSelectedOption(objectSelected)
}

function renderAdresses(typeAddress, addresses) {
  return typeAddress === TYPE_MODAL_CHANGE_SHIPPING_ADDRESS
    ? renderAdressesShipping(addresses)
    : null
}

export const renderAdressesShipping = addresses =>
  addresses?.map((address) => {
    const { deliveryModes, formattedAddress } = address
    
    return {
      address: formattedAddress,
      cost: deliveryModes && deliveryModes[0]?.cost,
      deliveryPolicyId: deliveryModes && deliveryModes[0]?.deliveryPolicyId,
      externalId: deliveryModes && deliveryModes[0]?.externalId,
      geographicalLevels: address.geographicalLevels,
      handleDays: deliveryModes && deliveryModes[0]?.handleDays,
      id: address.id,
      shippingMethodId: deliveryModes && deliveryModes[0]?.shippingMethodId,
    }
  }) || []
