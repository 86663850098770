import Translate from 'natura-commons/translate'

import {
  ACTION_BUTTON,
  ACTION_MULTIPLES_BUTTON,
  TYPE_ALERT_INCREMENT,
  TYPE_ALERT_KIT_PRODUCT,
  TYPE_ALERT_KIT_PRODUCT_DETAILS,
  TYPE_ALERT_MESSAGE,
  TYPE_MODAL_CYCLES,
  TYPE_MODAL_DAMAGED_PRODUCT,
  TYPE_MODAL_DRAFT,
  TYPE_MODAL_ERROR,
  TYPE_MODAL_IMPERSONATION,
  TYPE_MODAL_INCREMENT,
  TYPE_MODAL_INFO,
  TYPE_MODAL_INTERESTED_PRODUCT,
  TYPE_RADIO_MESSAGE
} from 'redux/actions/constants'
import {
  INDEX_MODAL_IMPERSONATION,
  INDEX_MODAL_PERSON
} from 'commons/constants/profile/index'
import {
  CANCEL_POOLING,
  QUANTITY_OPTIONAL_KIT,
  TIMEOUT_ALERT_TOAST
} from 'commons/constants/cart'
import { objInterestedProduct } from 'commons/interactor/alerts/mapper'
import { getError, getErrorStripe } from 'commons/presenter/errors/responseErros'
import KitProduct from 'commons/assets/images/kit-product.png'
import IconKitInicio from 'commons/assets/images/ic-kit-inicio.png'
import IconNoConnection from 'components/icons/no-wifi'
import cartNotCalculatedIcon from 'commons/assets/images/ic-not-calculated.svg'
import { showToast } from 'redux/actions/toast/toastAction'
import { ARRAY_NETWORK_ERRORS_MESSAGES } from 'commons/constants/error'
import { optionalKitAccept, starterKitAccept } from 'dataLayer/dataLayerPush'
import { showDateByCountry } from 'commons/helpers/date'

const translate = new Translate()

const INTEM_GIFT_ID = 4

export const getAlertImpersonation = detailsPerson => {
  const roles = [
    { Id: INDEX_MODAL_PERSON, Value: translate.byKey('me') },
    { Id: INDEX_MODAL_IMPERSONATION, Value: translate.byKey('otherCn') }
  ]
  const alert = {
    id: 1,
    type: TYPE_RADIO_MESSAGE,
    typeAction: ACTION_BUTTON,
    typeModal: TYPE_MODAL_IMPERSONATION,
    title: `${translate.byKey('hello')} ${detailsPerson.firstName}!`,
    show: true,
    titleButtonConfirm: translate.byKey('accept'),
    body: `<div class="mt-4"><strong>${translate.byKey(
      'makeToOrder'
    )}</strong>`,
    radio: roles
  }
  return alert
}

export const errorsDescription = errors => {
  let descriptionErrors = ''
  if (errors && Array.isArray(errors)) {
    errors.map(error => {
      descriptionErrors += `, ${getErroByCode(error) || ''}`
    })
    return descriptionErrors.slice(2)
  }
  descriptionErrors = errors;
  return descriptionErrors
}

const getErroByCode = error => {
  if (
    error.extensions &&
    error.extensions.response &&
    error.extensions.response.status &&
    [502, 504].includes(error.extensions.response.status)
  ) {
    return getError({ code: error.extensions.response.status }, true)
  }
  if ((error.code || error.message) && !error.extensions) {
    return getError({
      code: error.code,
      description: error.message,
      productCode: error.productCode
    })
  }
  if (
    error.extensions.body &&
    error.extensions.body.errorCode
  ) {
    const { body } = error.extensions
    return getError({ code: body.errorCode, description: body.message })
  }
  if (
    error.extensions &&
    error.extensions.response &&
    error.extensions.response.body
  ) {
    let { body } = error.extensions.response
    const path = error.path && error.path[0]
    if (body.code) {
      if (path === 'cartAddItems') {
        return getError({
          code: body.code,
          description: body.message,
          path
        })
      }
      return getError({ code: body.code, description: body.message })
    }
    if (body.messages) {
      body = body.messages[0]
      return getError({ code: body.code, description: body.message })
    }
    if (body.message && !body.errors) {
      return getError({ code: body.code, description: body.message })
    }
    if (body.errors && body.errors.length) {
      const pendingPromotionErrors = body.errors.filter(error => error.message === 'checkout.promotion.pending');

      if (pendingPromotionErrors.length > 0) {
        return getError({
          code: body.code,
          description: pendingPromotionErrors[0].message,
          promotionsIds: pendingPromotionErrors.map(error => error.promotionId)
        })
      }
      return getError({
        code: body.code,
        description: body.errors[0].message
      })
    }
    if (body.msg) {
      body = body.msg.error
      return getError({
        code: body.code || body.errorCode,
        description: body.message
      })
    }
    if (body.error) {
      const { errorMessage, message } = body.error
      return getError({ code: '', description: errorMessage || message, path })
    }
    if (body.errorCode && body.errorMessage) {
      return getError({
        code: body.errorCode,
        description: body.message
      })
    }
  }
}

export const getAlertErrorStripe = (
  errors
) => {
  if (errors[0].extensions) {
    const error_stripe = errors[0].message.toString();
    const error_parsed = error_stripe.match(/\"(.*?)\"/);
    const error = error_parsed[1].toString()
      .replace('stripe-api.', '')
      .replace('card_declined.', '');

    const message = getErrorStripe(error);
    return getMessage(message, null, null, true, { errors })
  } else {
    if (errors[0].message.code == 'parameter_invalid_empty') {
      const message = getErrorStripe('parameter_invalid_empty');
      return getMessage(message, null, null, true, { errors })
    } else {
      return getMessage(errors[0].message, null, null, true, { errors })
    }
  }
}

export const getAlertError = (
  errors,
  redirectLogin = false,
  errorKey = null,
  dispatch
) => {
  if (
    errors.message &&
    ARRAY_NETWORK_ERRORS_MESSAGES.includes(errors.message)
  ) {
    let showModal = true
    if (window.location.pathname !== '/login') {
      dispatch(
        showToast({
          text: translate.byKey('toastFailedConnection'),
          type: 'error',
          show: true,
          svgIcon: IconNoConnection(),
          hideIcon: true,
          timeout: TIMEOUT_ALERT_TOAST
        })
      )
      showModal = false
    }

    return getMessage(
      translate.byKey('toastFailedConnection'),
      redirectLogin,
      null,
      showModal,
      { errors }
    )
  }

  let description = translate.byKey('systemInMaintenanceBody')
  if (errorKey && errors && errors.length === 0) {
    description = translate.byKey(errorKey)
  } else if (errors && errors.length > 0) {
    description = errorsDescription(errors)
  } else if (errors && errors.extensions) {
    description = getErroByCode(errors)
  }

  if (errors && errors.length > 0 && errors[0].code === CANCEL_POOLING) {
    const errorMessage = translate.byKey('cancelPooling')

    dispatch(
      showToast({
        icon: cartNotCalculatedIcon,
        text: errorMessage,
        type: 'notCalculated',
        show: true,
        autoHide: true,
        timeout: TIMEOUT_ALERT_TOAST
      })
    )

    return getMessage(
      errorMessage,
      redirectLogin,
      null,
      false,
      { errors }
    )
  }

  return getMessage(description, redirectLogin, null, true, { errors })

}

export const getAlertTitleBody = (
  headerKey,
  bodyKey = null,
  redirectLogin = false
) => {
  const alert = getMessage(
    translate.byKey(bodyKey),
    redirectLogin,
    translate.byKey(headerKey)
  )
  return alert
}

const getMessage = (description, redirectLogin, title = null, show = true, extra = {}) => ({
  id: 1,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_BUTTON,
  typeModal: TYPE_MODAL_ERROR,
  title: title || translate.byKey('warningTitle'),
  show,
  titleButtonConfirm: translate.byKey('okUnderstand'),
  body: description,
  redirectLogin,
  ...extra,
})

export const getAlertInitialKits = funcButtonConfirmKitInitial => {
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: `<div class='mt-3'/><span class="header-kits">${translate.byKey(
      'initialKits'
    )}</span>`,
    show: true,
    titleButtonTwo: translate.byKey('okUnderstand'),
    titleButtonOne: translate.byKey('seeDetails'),
    onPressOne: funcButtonConfirmKitInitial,
    body: `<span>${translate.byKey('initialKitsDescription')}</span>
    <div class="w-100 text-center"> <img src=${IconKitInicio} class='body-imagem-kit-inicio' /></div>`
  }

  return alert
}

export const getAlertInitialKitsAdded = funcButtonConfirm => {
  const alert = {
    id: 2,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_BUTTON,
    title: ` <div class='mt-3'/><span class="header-kits">${translate.byKey(
      'initialKitsAdded'
    )}</span>`,
    show: true,
    titleButtonConfirm: translate.byKey('startMyPetitions'),
    handleConfirm: funcButtonConfirm,
    body: `${translate.byKey('initialKitsAddedDescription')}
          <img src=${IconKitInicio} class='body-imagem-kit-inicio' />`
  }

  return alert
}

export const getAlertsProductInterested = (
  product,
  onPressOne,
  onPressTwo,
  errorMessage
) => {
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    typeModal: TYPE_MODAL_INTERESTED_PRODUCT,
    title: '',
    show: true,
    body: '',
    errorMessage,
    interestedProduct: { ...objInterestedProduct, item: product },
    titleButtonOne: translate.byKey('notNow'),
    titleButtonTwo: translate.byKey('buttonAdd'),
    onPressOne: () => onPressOne(product),
    onPressTwo: () => onPressTwo(product)
  }

  return [alert]
}

export const getAlertsProductDamaged = (
  product,
  onPressOne,
  onPressTwo,
  errorMessage,
  dateEnd = ''
) => {

  const buttons =
    product.itemTypeId !== INTEM_GIFT_ID
      ? {
        titleButtonOne: translate.byKey('notNow'),
        titleButtonTwo: translate.byKey('buttonAdd'),
        onPressOne: () => onPressOne(product),
        onPressTwo: () => onPressTwo(product)
      }
      : {
        damagedProduct: { ...objInterestedProduct, item: product, dateEnd },
        titleButtonConfirm: translate.byKey('okUnderstand'),
        handleConfirm: () => onPressTwo(product)
      }
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction:
      product.itemTypeId !== INTEM_GIFT_ID
        ? ACTION_MULTIPLES_BUTTON
        : ACTION_BUTTON,
    typeModal: TYPE_MODAL_DAMAGED_PRODUCT,
    title: '',
    show: true,
    body: '',
    errorMessage,
    damagedProduct: { ...objInterestedProduct, item: product, dateEnd },
    ...buttons
  }

  return [alert]
}

export const getAlertInitialKitsDetails = products => {
  if (products && products.length > 0 && products[0] != null) {
    const { productCode, description, price, productItems, picture, points } = products[0]
    return {
      id: 1,
      type: TYPE_ALERT_KIT_PRODUCT_DETAILS,
      typeModal: TYPE_ALERT_KIT_PRODUCT_DETAILS,
      typeAction: ACTION_BUTTON,
      show: true,
      kitProduct: {
        title: `${description}`,
        code: `${productCode}`,
        value: `${price}`,
        picture,
        points,
        productItems
      }
    }
  }
  return undefined
}

export const getAlertOptionalKit = (funcButtonOne, funcButtonTwo) => ({
  id: 3,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_MULTIPLES_BUTTON,
  title: ` <div class="my-3"><span class="header-kits">${translate.byKey(
    'optionalKit'
  )}</span>`,
  show: true,
  titleButtonOne: translate.byKey('doNotWant'),
  titleButtonTwo: translate.byKey('doWant'),
  onPressOne: funcButtonOne,
  onPressTwo: funcButtonTwo,
  body: `<div>${translate.byKey('optionalKitDescription')}
            <div class="w-100 text-center"><img src=${IconKitInicio} class='body-imagem-kit-inicio'/></div>
          </div>`
})

export const getAlertOptionalKitsDetails = (
  products,
  funcButtonOne,
  funcButtonTwo
) => {
  if (products && products.length > 0 && products[0] != null) {
    const { productCode, description, price, productItems } = products[0]
    const descriptionProduct = kitDescription(productItems)
    return {
      id: 3,
      type: TYPE_ALERT_KIT_PRODUCT,
      typeAction: ACTION_MULTIPLES_BUTTON,
      title: `<div class="mt-3" /><span class="header-kits-product">${translate.byKey(
        'optionalKit'
      )}</span>`,
      show: true,
      titleButtonOne: translate.byKey('dontWant'),
      titleButtonTwo: translate.byKey('buttonAdd'),
      onPressOne: funcButtonOne,
      onPressTwo: () =>
        funcButtonTwo({ code: productCode, quantity: QUANTITY_OPTIONAL_KIT, mandatory: false }),
      kitProduct: {
        title: `${description}`,
        code: `${productCode}`,
        value: `${price}`
      },
      body: `<div class='w-100 text-center'/>
            <img src=${KitProduct} class='body-imagem-kit-product'/>
           </div>
           <div class='w-100 text-left'/>
            <span class='body-text-kit-product'>${descriptionProduct}<br/></span>
           </div>`
    }
  }
  return undefined
}

export const getAlertOptionalKitsCarousel = (
  products,
  funcButtonOne,
  funcButtonTwo
) => {
  if (products) {
    return {
      id: 3,
      type: TYPE_ALERT_KIT_PRODUCT,
      typeAction: ACTION_MULTIPLES_BUTTON,
      title: `<div class="mt-3" /><span class="header-kits-product">${translate.byKey('optionalKit')}</span>`,
      show: true,
      titleButtonOne: translate.byKey('dontWant'),
      titleButtonTwo: translate.byKey('buttonAdd'),
      onPressOne: funcButtonOne,
      onPressTwo: () => {
        funcButtonTwo({ products, quantity: QUANTITY_OPTIONAL_KIT, mandatory: false })
        optionalKitAccept()
      },
      kitProduct: { products },
      body: ''
    }
  }
  return undefined
}

export const getAlertMandatoryKitsCarousel = (
  products,
  funcButtonOne
) => {
  if (products) {
    return {
      id: 1,
      type: TYPE_ALERT_KIT_PRODUCT,
      typeAction: ACTION_BUTTON,
      title: ` <div class='mt-3'/><span class="header-kits-product">${translate.byKey('initialKits')}</span>`,
      show: true,
      titleButtonConfirm: products?.length > 1 ? translate.byKey('buttonAdd') : translate.byKey('okUnderstand'),
      handleConfirm: () => {
        products?.length >= 1 && funcButtonOne && funcButtonOne({ products, quantity: QUANTITY_OPTIONAL_KIT, mandatory: true })
        starterKitAccept()
      },
      kitProduct: { products },
      body: ``
    }
  }
  return undefined
}

const kitDescription = productItems => {
  let descriptionProduct = ''
  if (productItems) {
    productItems.map((product) => {
      descriptionProduct += `, ${`0${product.bundleQuantity}`.slice(-2)} ${product.description
        }`
    })
    return descriptionProduct.slice(2)
  }
  return descriptionProduct
}

export const getAlertRejectOptionalKit = (funcButtonOne, funcButtonTwo) => {
  const alert = {
    id: 3,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: translate.byKey('optionalKitRejectTitle'),
    show: true,
    titleButtonOne: translate.byKey('not'),
    titleButtonTwo: translate.byKey('yes'),
    onPressOne: funcButtonOne,
    onPressTwo: funcButtonTwo,
    body: translate.byKey('optionalKitRejectBody')
  }

  return alert
}

export const getCycleOrderAlert = cycles => {
  const radios = []
  cycles.map((cycle, index) => {
    const cycleShow = cycle.cycle.cycleCode
      .toString()
      .replace(
        /\d{4}/g,
        `${translate.byKey('cycle')} ${cycle.cycle.cycleCode
          .toString()
          .slice(0, 4)}/`
      )
    const cycleResult = {
      Id: index,
      cycle: cycle.cycle.cycleCode,
      Value: `${cycleShow} (${showDateByCountry(index > 0 ? cycle.orderDate : new Date())})`,
      subCycle: cycle.kpiSubcycle,
      orderDate: cycle.orderDate
    }

    radios.push(cycleResult)
  })
  return {
    id: 3,
    type: TYPE_RADIO_MESSAGE,
    typeAction: ACTION_BUTTON,
    typeModal: TYPE_MODAL_CYCLES,
    title: `<span class="d-block mt-2">${translate.byKey('cycle')}</span>`,
    show: true,
    titleButtonConfirm: translate.byKey('accept'),
    body: translate.byKey('cycleOrderAlertBody'),
    radio: radios
  }
}

export const getAlertCartDraftPreOrder = (
  cycle,
  dateCart,
  funcButtonOne,
  funcButtonTwo
) => {
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: getTitleDraft(
      translate.byKeyAndParamValue('alertCartDraftPreOrderTitle', cycle)
    ),
    show: true,
    titleButtonOne: translate.byKey('draft'),
    titleButtonTwo: translate.byKey('preOrder'),
    onPressOne: funcButtonOne,
    onPressTwo: funcButtonTwo,
    body: getBodyDraft(
      translate.byKeyAndParamValue('alertCartDraftPreOrderBody', dateCart),
      translate.byKey('alertCartDraftPreOrderSubBody')
    )
  }
  return alert
}

export const getAlertPreOrder = (cycle, funcButtonOne, funcButtonTwo) => {
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: getTitleDraft(
      translate.byKeyAndParamValue('alertCartDraftPreOrderTitle', cycle)
    ),
    show: true,
    titleButtonOne: translate.byKey('newOrder'),
    titleButtonTwo: translate.byKey('redeemOrder'),
    onPressOne: funcButtonOne,
    onPressTwo: funcButtonTwo,
    body: getBodyDraft(
      translate.byKey('alertPreOrderBody'),
      translate.byKey('alertPreOrderSubBody')
    )
  }
  return alert
}

export const getAlertCartDraft = (dateCart, funcButtonOne, funcButtonTwo) => {
  const alert = {
    id: 1,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    typeModal: TYPE_MODAL_DRAFT,
    title: translate.byKey('alertCartDraftTitle'),
    show: true,
    titleButtonOne: translate.byKey('deleteOrder'),
    titleButtonTwo: translate.byKey('redeemOrder'),
    onPressOne: funcButtonOne,
    onPressTwo: funcButtonTwo,
    body: `${translate.byKeyAndParamValue(
      'alertCartDraftBody',
      dateCart
    )}<br> <strong class="d-block mt-3">
    ${translate.byKey('alertCartDraftSubBody')}</strong>`
  }
  return alert
}

export const getAlertUnavailableStockOptions = (
  funcButtonTwo
) => {
  const alert = {
    id: 4,
    type: TYPE_MODAL_INFO,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: translate.byKey('notAvailable'),
    show: true,
    titleButtonOne: translate.byKey('close'),
    titleButtonTwo: translate.byKey('findCartOptions'),
    onPressOne: () => {},
    onPressTwo: funcButtonTwo,
    body: translate.byKey('unavailableAlternatives')
  }
  return alert
}

export const getAlertLimitedStockGuaranteedSale = (
  product,
  funcButtonOne,
  funcButtonTwo
) => {
  const alert = {
    id: 4,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    title: translate.byKey('limitedStock'),
    show: true,
    titleButtonOne: translate.byKey('notNow'),
    titleButtonTwo: translate.byKey('letMeKnowButtonDialog'),
    onPressOne: funcButtonOne,
    onPressTwo: () => funcButtonTwo(),
    body: `${translate.byKey(
      'alertLimitedStockBody'
    )}<br> <strong class="d-block mt-2 pt-1">${translate.byKey(
      'alertLimitedStockSubBody'
    )}</strong>`
  }
  return alert
}

export const getAlertLimitedStockPlanBGuaranteedSale = (
  product,
  funcButtonOne,
  funcButtonTwo
) => ({
  id: 4,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_MULTIPLES_BUTTON,
  title: translate.byKey('limitedStock'),
  show: true,
  titleButtonOne: translate.byKey('letMeKnowButtonDialog'),
  titleButtonTwo: translate.byKey('seeSimilarProductsDialogButton'),
  onPressOne: funcButtonOne,
  onPressTwo: () => funcButtonTwo(),
  body: `${translate.byKey(
    'seeSimilarProducts'
  )}<br><strong class="d-block mt-2 pt-1" >${translate.byKey(
    'alertLimitedStockPlanBGuaranteedSaleSubBody'
  )}</strong>`
})

export const showModalIncrementGuaranteedSale = (
  item,
  functionOnChangeValue
) => ({
  id: 0,
  type: TYPE_ALERT_INCREMENT,
  typeAction: ACTION_MULTIPLES_BUTTON,
  typeModal: TYPE_MODAL_INCREMENT,
  titleButtonOne: translate.byKey('notNow'),
  titleButtonTwo: translate.byKey('confirm'),
  title: `<span class="header-warn-me">${translate.byKey(
    'alertMeExclamation'
  )}</span>`,
  show: true,
  body: `<div><span>${translate.byKey(
    'modalIncrementGuaranteedSaleBody'
  )}</span>
      <div class='w-100 mt-3'/>
      <span class='text-warn-me'> ${translate.byKey(
    'modalIncrementGuaranteedSaleSubBody'
  )}</span>
      </div>`,
  increment: {
    quantity: item.quantity,
    onValueChangeIncrement: value => functionOnChangeValue(value, item)
  }
})
export const getNotFoundProductAlert = (codes, showImportAlert) => ({
  id: 1,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_BUTTON,
  title: `<span class="d-block mt-2">${translate.byKey(
    'intemsNotFoundTitle'
  )}</span>`,
  show: true,
  titleButtonConfirm: translate.byKey('okUnderstand'),
  handleConfirm: showImportAlert ? () => showImportAlert() : () => { },
  body: `${translate.byKey('intemsNotFound')} ${codes}.`
})

const getTitleDraft = title => `<div class="mt-3">${title}</div>`

const getBodyDraft = (firstLine, secondLine) =>
  `<div class="mt-4">${firstLine}
      <br>
        <strong class="d-block mt-3 mb-2">
          ${secondLine}
        </strong>
   </div>`

export const getWindowNotFoundAlert = () => ({
  id: 1,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_BUTTON,
  title: translate.byKey('windowNotFoundTitle'),
  show: true,
  titleButtonConfirm: translate.byKey('okUnderstand'),
  handleConfirm: () => { },
})
