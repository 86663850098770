import Parameter from 'natura-commons/service/Parameter'

import { verifyError } from 'redux/actions/alerts/alertsAction'
import { mapShowCaseToCompanyBrand } from 'redux/actions/companyBrand/companyBrandAction'
import { getAllTitles } from 'redux/actions/promotion/promotionAction'
import {
  mapMenu,
  mapBanner,
  mapCategories,
  isRenderMenu,
  mapVitrine,
  mapCartWindow,
  mapWindows,
} from 'commons/interactor/parameter/mapper'
import { getCommercialInfoData } from 'commons/interactor/person/interactor'
import { mapProduct } from 'commons/interactor/products/mapper'
import { getPropertys } from 'commons/interactor/parameter/interactor'
import { showLoad } from 'redux/actions/load/loadAction'
import { recieveLeaverageItems } from 'redux/actions/search/customSearchAction'
import {
  FAIL_LOAD_INFORMATION,
  FAIL_LOAD_LEVERAGE
} from 'commons/constants/error'

import {
  highlightedAvailability,
  paginedPromotions
} from 'redux/actions/products/productsActions'

import {
  setFilterSelected,
  setInitialFilterAfterSearch
} from 'redux/actions/sorter/search/sorterSearchAction'

import { creators as userSession } from 'redux/modules/userSession'

import {
  SET_BANNERS_HOME,
  SET_CART_BANNER,
  GET_BAG_INFO_TEXT,
  SET_INITIAL_MENU,
  GET_LEAVERAGE,
  SET_SELECTED_LEAVERAGE,
  CLEAR_LEVERAGE,
  GET_INTERNAL,
  SET_INITIAL_NBO,
  SET_INITIAL_CART_WINDOW,
  CLEAR_MENU,
  ACTIVE_SEARCH_BY_DESCRIPTION,
  PAGINATION_SIZE,
  OPTMIZE_SEARCH,
  SEARCH_INVALIDATE,
  SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL,
  SET_MIN_SPLIT_VALUE_CN,
  GET_SPLIT_PARAMS,
  PRODUCTS_WINDOWS_HOME,
  SET_BANNER_DEFAULT,
  SET_COMISSION,
  CHANGE_PAYMENT_METHOD_PARAM,
  SET_SHOW_ORDERS_HISTORY,
  SET_SHOW_PICKUP_POINT,
  GEO_STRUCTURE_LEVEL,
  SET_SUCURSAL_FILTERS,
  SET_COUNTRY_PHONE_NUMBER,
  SET_TAXES_PARAMS,
  SHIPPING_METHOD,
  SET_MENU,
  SET_SALES_OPPORTUNITY,
  SET_INTERACTIVE_MAGAZINE_LINK,
  SET_IMPORTER_MAGAZINE_LINK,
  SET_HOME_PARAMETERS_SEARCHED,
  SET_SHOW_CREDT_INFO,
  SET_NEW_PROMOTION
} from '../constants'
import { navigateTo, setSelectedMenu } from '../router/routerAction'
import { getPendingPayments } from '../bag/bagAction'
import { PROMOTIONS_HOME } from '../router/constants'

const parameter = new Parameter()

export const clearLeverage = () => ({
  type: CLEAR_LEVERAGE
})

export const getInitialMenu = (isComercial = false) => async (
  dispatch,
  getState
) => {
  const {
    userSession: {
      impersonation: {
        isImperson
      }
    }
  } = getState()
  const { countryCode, companyId, businessModelId } = getPropertys(
    getCommercialInfoData(getState)
  )
  if (isComercial && !isRenderMenu(getState(), isImperson)) return
  parameter.resolver(
    (response) => {
      dispatch({ type: SET_INITIAL_MENU, menu: mapMenu(response.menus), isImperson })
      dispatch({ type: SET_BANNER_DEFAULT, banner: response.bannerDefault })
      dispatch(recieveLeaverageItems(mapMenu(response.menus)))
      dispatch(userSession.postCommercialInfoRequest())
    },
    (error) => {
      dispatch({ type: SET_INITIAL_MENU, menu: mapMenu(null) })
      verifyError(dispatch, error, false, FAIL_LOAD_INFORMATION)
    },
    await parameter.getMenu(countryCode, companyId, businessModelId)
  )
}

export const clearMenu = () => dispatch => dispatch({ type: CLEAR_MENU })

export const getNbo = () => async (dispatch, getState) => {
  const { countryCode, companyId, businessModelId, personId } = getPropertys(
    getCommercialInfoData(getState)
  )
  parameter.resolver(
    (response) => {
      const { products = [], description = '' } = response
      dispatch({
        type: SET_INITIAL_NBO,
        products: mapProduct((products && products.product) || []),
        description
      })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    await parameter.getNbo(countryCode, companyId, businessModelId, personId)
  )
}

export const getCartWindow = (displayTaxes, taxContext) => async (dispatch, getState) => {
  const { parameterReducer: { cartWindows } } = getState()

  if (!cartWindows || !cartWindows.length) {
    const { countryCode, companyId, businessModelId, personId } = getPropertys(
      getCommercialInfoData(getState)
    )
    if (countryCode && companyId && businessModelId && personId) {
      parameter.resolver(
        (response) => {
          dispatch({
            type: SET_INITIAL_CART_WINDOW,
            windows: mapCartWindow(response.windows),
          })
        },
        (error) => {
          verifyError(dispatch, error)
        },
        await parameter.getCartWindow(
          countryCode,
          companyId,
          businessModelId,
          displayTaxes,
          taxContext
        )
      )
    }
  }
}

export const getPaginationSize = () => async (dispatch, getState) => {
  const { countryCode, companyId } = getPropertys(
    getCommercialInfoData(getState)
  )
  parameter.resolver(
    (response) => {
      dispatch({
        type: PAGINATION_SIZE,
        size: response.pageSize
      })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    await parameter.getPaginationSize(countryCode, companyId)
  )
}

export const getBannerHome = commercialInfo => (dispatch, getState) => {
  if (!commercialInfo) {
    commercialInfo = getCommercialInfoData(getState)
  }
  const { countryCode, companyId, businessModelId } = getPropertys(
    commercialInfo
  )
  parameter.resolver(
    (response) => {
      dispatch({ type: SET_BANNERS_HOME, banners: mapBanner(response) })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    parameter.getBanner(countryCode, companyId, businessModelId)
  )
}

export const getCartMarketing = commercialInfo => (dispatch, getState) => {
  const { parameterReducer: { cartBanner, bagInfoText } } = getState()
  if (!cartBanner?.recovered || !bagInfoText?.recovered) {
    if (!commercialInfo) {
      commercialInfo = getCommercialInfoData(getState)
    }
    const { countryCode, companyId, businessModelId } = getPropertys(
      commercialInfo
    )
    parameter.resolver(
      (response) => {
        dispatch({ type: SET_CART_BANNER, cartBanner: response.cartBanner })
        dispatch({ type: GET_BAG_INFO_TEXT, bagInfoText: response.bagInfoText })
      },
      (error) => {
        verifyError(dispatch, error)
      },
      parameter.getCartMarketing(countryCode, companyId, businessModelId)
    )
  }
}

export const getLeverage = (
  windowLevelOneId,
  levelTwoSelectionId = null,
  menuToSelect = null,
  fromHome = false
) => (dispatch, getState) => {
  const { countryCode, companyId, businessModelId } = getPropertys(
    getCommercialInfoData(getState)
  )
  dispatch(showLoad(true))
  dispatch(clearLeverage())
  dispatch({ type: SEARCH_INVALIDATE })
  parameter.resolver(
    (response) => {
      dispatch(showLoad(false))
      const categories = mapCategories(response)
      dispatch({ type: GET_LEAVERAGE, categories })
      if (levelTwoSelectionId) {
        const category = categories.find(
          category => category.code === levelTwoSelectionId
        )
        if (category) {
          dispatch(
            selectLeaverage(windowLevelOneId, {
              ...category,
              ...menuToSelect
            })
          )
          dispatch(setSelectedMenu(menuToSelect))
          dispatch(navigateTo(PROMOTIONS_HOME))
        }
      }
      if (fromHome && menuToSelect) {
        const submenu = categories && categories.length > 0 ? 
          menuToSelect.find(item => item.code === categories[0].code) : null;

        if (submenu) {
          dispatch(
            selectLeaverage(windowLevelOneId, {
              ...categories[0],
              ...submenu
            })
          )
          dispatch(setSelectedMenu(submenu))
          dispatch(navigateTo(PROMOTIONS_HOME))
        }
      }
    },
    (error) => {
      dispatch(showLoad(false))
      verifyError(dispatch, error, false, FAIL_LOAD_LEVERAGE)
    },
    parameter.getLeverage(windowLevelOneId, countryCode, companyId, businessModelId)
  )
}

export const selectLeaverageDispatch = (windowLevelOneId, leaverage) => dispatch => dispatch(selectLeaverage(windowLevelOneId, leaverage))

export const selectLeaverage = (windowLevelOneId, leaverage) => ({
  type: SET_SELECTED_LEAVERAGE,
  selected: { windowLevelOneId, leaverage }
})

export const getInternal = (windowLevelOneId, windowLevelTwoId, displayTaxes, taxContext) => (
  dispatch,
  getState
) => {
  const { countryCode, companyId, businessModelId } = getPropertys(
    getCommercialInfoData(getState)
  )

  dispatch({ type: SEARCH_INVALIDATE })
  dispatch(setFilterSelected([], true))
  dispatch(showLoad(true))
  parameter.resolver(
    (response) => {
      if (response) {
        const highlighted = mapVitrine(response.highlighted, displayTaxes, taxContext)
        const vitrine = mapVitrine(response.vitrine, displayTaxes, taxContext)
        const list = []
        vitrine.map(item => item.products.map(product => list.push(product)))
        dispatch({ type: GET_INTERNAL, products: list, highlighted, vitrine })
        dispatch(paginedPromotions())
        dispatch(highlightedAvailability())
        dispatch(setInitialFilterAfterSearch(true))
      }
      dispatch(showLoad(false))
    },
    (error) => {
      verifyError(dispatch, error)
      dispatch(showLoad(false))
    },
    parameter.getInternal(
      countryCode,
      companyId,
      businessModelId,
      windowLevelOneId,
      windowLevelTwoId,
      displayTaxes,
      taxContext
    )
  )
}

export const getHomeWindow = () => (
  dispatch,
  getState
) => {
  const {
    parameterReducer: {
      windowsHome
    },
    taxesReducer: {
      taxParams
    },
    userSession: {
      commercialInfo,
      detailsPerson
    }
  } = getState()

  const { displayTaxes, taxesParameters } = taxParams || {}

  const taxContext = {
    taxesParameters,
    geographicalLevels: detailsPerson.addresses[0].geographicalLevels,
    userInfo: {
      addresses: detailsPerson.addresses,
      countryCode: detailsPerson.countryCode,
      companyId: detailsPerson.companyId,
      orderProfile: commercialInfo.orderProfile
    }
  }

  if (!windowsHome.length) {
    const { countryCode, companyId, businessModelId } = getPropertys(
      getCommercialInfoData(getState)
    )

    if (countryCode && companyId && businessModelId) {
      dispatch(showLoad(true))
      parameter.resolver(
        (response) => {
          if (response) {
            dispatch({ type: PRODUCTS_WINDOWS_HOME, windows: mapWindows(response.windows) })
          }
          dispatch(showLoad(false))
        },
        (error) => {
          verifyError(dispatch, error)
          dispatch(showLoad(false))
        },
        parameter.getHomeWindow(
          countryCode,
          companyId,
          businessModelId,
          displayTaxes,
          taxContext
        )
      )
    }
  }
}

export const activeSearchByDescription = () => (dispatch, getState) => {
  const {
    userSession: {
      detailsPerson,
      impersonation: {
        isImperson,
        person: { personData },
      },
    },
  } = getState()
  const { enableTextSearch, shouldOptimize } = isImperson
    ? personData
    : detailsPerson
  dispatch({
    type: ACTIVE_SEARCH_BY_DESCRIPTION,
    active:
      enableTextSearch !== null && enableTextSearch !== undefined
        ? enableTextSearch
        : false
  })

  dispatch({ type: OPTMIZE_SEARCH, active: shouldOptimize })
}

export const getExternalOrderSearchUrl = commercialInfo => (dispatch, getState) => {
  if (!commercialInfo) {
    commercialInfo = getCommercialInfoData(getState)
  }
  const { countryCode, companyId, businessModelId } = getPropertys(
    commercialInfo
  )
  parameter.resolver(
    (response) => {
      dispatch({ type: SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL, externalOrderSearchUrls: response })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    parameter.getExternalOrderSearchUrl(countryCode, companyId, businessModelId)
  )
}


export const getChangePaymentMethod = () => async (dispatch) => {
  parameter.resolver(
    (response) => {
      dispatch({ type: CHANGE_PAYMENT_METHOD_PARAM, payload: response })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    await parameter.getChangePaymentMethod()
  )
}


export const setComissionDispatch = ({ discountProfitability, profitabilityTable, totalPoints }) => (dispatch) => {
  let comission
  if (discountProfitability && discountProfitability > 0) {
    comission = discountProfitability
  } else {
    const range = profitabilityTable.map((range, index) => ({
      ...range,
      index
    }))
      .find(range => !range.to || (totalPoints >= range.from && totalPoints <= range.to))
    comission = range?.profitability
  }

  dispatch(
    {
      type: SET_COMISSION,
      comission
    }
  )
}


export const getHomeParameters = commercialInfo => (dispatch, getState) => {
  if (!commercialInfo) {
    commercialInfo = getCommercialInfoData(getState)
  }

  const {
    userSession: {
      impersonation: {
        isImperson
      },
      detailsPerson: {
        addresses,
        countryCode,
        companyId
      }
    }
  } = getState()

  const taxContext = {
    geographicalLevels: addresses[0].geographicalLevels,
    userInfo: {
      addresses,
      countryCode,
      companyId,
      orderProfile: commercialInfo.orderProfile
    }
  }

  parameter.resolver(
    (response) => {
      const menu = mapMenu(response.menuParam.menus)

      dispatch({ type: SET_HOME_PARAMETERS_SEARCHED })
      dispatch({ type: SET_BANNERS_HOME, banners: mapBanner(response.bannerParam) })
      dispatch({ type: SET_INITIAL_MENU, menu, isImperson, enableOrdersHistory: response.enableOrdersHistory })
      dispatch({ type: SET_MENU, menu, isImperson, enableOrdersHistory: response.enableOrdersHistory })
      dispatch({ type: SET_BANNER_DEFAULT, banner: response.menuParam.bannerDefault })
      dispatch({ type: SET_SALES_OPPORTUNITY, salesOpportunity: response.salesOpportunity || [] })
      dispatch({ type: SET_TAXES_PARAMS, payload: response.taxParams })
      dispatch({ type: PRODUCTS_WINDOWS_HOME, windows: mapWindows(response.homeWindowParam.windows) })
      dispatch({ type: PAGINATION_SIZE, size: response.paginationParam.pageSize, orderSearchPageSize: response.paginationParam.ordersSearchPageSize })
      dispatch({ type: SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL, externalOrderSearchUrls: response.externalOrderUrlParam })
      dispatch({ type: SET_SHOW_ORDERS_HISTORY, enableOrdersHistory: response.enableOrdersHistory })
      dispatch({ type: SET_NEW_PROMOTION, enableNewPromotion: response.enableNewPromotion })
      dispatch({ type: SET_SHOW_PICKUP_POINT, enablePickupPoint: response.enablePickupPoint })
      dispatch({ type: SET_SHOW_CREDT_INFO, enableCreditInfo: response.enableCreditInfoComponent })
      dispatch({ type: SET_INTERACTIVE_MAGAZINE_LINK, interactiveMagazineLink: response.interactiveMagazineLink })
      dispatch({ type: SET_IMPORTER_MAGAZINE_LINK, magazineImporterUrl: response.importUrl })
      dispatch({ type: GEO_STRUCTURE_LEVEL, geoStructureLevel: response.geoStructureLevel })
      dispatch({ type: SHIPPING_METHOD, shippingMethod: response.shippingMethodParam })
      dispatch({ type: SET_SUCURSAL_FILTERS, sucursalFilters: response.sucursalFilters })
      dispatch({ type: SET_COUNTRY_PHONE_NUMBER, country: response.country })
      dispatch(mapShowCaseToCompanyBrand(response.companyBrands))
      dispatch(recieveLeaverageItems(mapMenu(response.menuParam.menus)))
      dispatch(userSession.postCommercialInfoRequest())

      if (!response.enablePickupPoint) {
        dispatch(getMinimumSplitValueCN(commercialInfo))
      }

      dispatch(getAllTitles())
    },
    (error) => {
      dispatch({ type: SET_HOME_PARAMETERS_SEARCHED })
      verifyError(dispatch, error)
    },
    parameter.getHomeParams({ ...getPropertys(commercialInfo), taxContext })
  )
}

export const getMinimumSplitValueCN = commercialInfo => async (dispatch, getState) => {
  if (!commercialInfo) {
    commercialInfo = getCommercialInfoData(getState)
  }

  const { countryCode, companyId, businessModelId } = getPropertys(
    commercialInfo
  )

  parameter.resolver(
    (response) => {
      dispatch({ type: SET_MIN_SPLIT_VALUE_CN, payload: response })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    await parameter.getMinimumSplitValueCN(countryCode, companyId, businessModelId)
  )
}

export const getSplitParams = () => async (dispatch) => {
  parameter.resolver(
    (response) => {
      dispatch({ type: GET_SPLIT_PARAMS, payload: response })
    },
    (error) => {
      verifyError(dispatch, error)
    },
    await parameter.getSplitParams()
  )
}
